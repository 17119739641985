<template>

        <div class="d-flex align-items-center w-100 border-bottom p-3">
            <div class="flex-shrink-0">
                <div class="rounded-circle img-thumbnail p-0" v-if="boda.perfil && boda.perfil.length>0">
                    <img :src="boda.perfil[0].ARCHIVO" :alt="boda.NOMBRE_PAREJA_1+' y '+boda.NOMBRE_PAREJA_2" width="72" height="72" class="rounded-circle">
                </div>
                <div class="rounded-circle border border-muted text-muted p-3" v-else>
                    <font-awesome-icon :icon="faCamera" style="font-size: 40px" />
                </div>
            </div>
            <div class="flex-grow-1 ms-3">
                <div class="d-flex w-100 justify-content-between align-items-center">
                    <div class="text-truncate">
                        <h5 class="mb-0 text-truncate">{{boda.NOMBRE_PAREJA_1}} y {{boda.NOMBRE_PAREJA_2}}</h5>
                        <p class="text-muted mb-0" v-if="boda.FECHA_BODA">
                            {{$filters.date(boda.FECHA_BODA)}}
                        </p>
                        <p class="text-muted mb-0" v-else>
                            Sin fecha
                        </p>
                    </div>
                    <div>
                        <router-link :to="{name: 'mesas.show', params: {id: boda.SLUG}}" class="btn btn-lg btn-primary">
                            <font-awesome-icon :icon="faGift" />
                            Ver mesa
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faGift, faCamera } from '@fortawesome/free-solid-svg-icons'
export default {
    props: ['data'],
    data(){
        return {
            faGift,
            faCamera
        }
    },
    components: {
        FontAwesomeIcon
    },
    computed:{
        boda(){
            return this.data || {}
        }
    }
}
</script>