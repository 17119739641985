<template>
    <div class="wrapper position-relative d-flex align-items-center pt-body pb-5">
        <img :src="ImgBG" alt="Encuentra una mesa de regalo" class="w-100 h-100 img-background position-absolute top-0 bottom-0 start-0 end-0">
        <div class="row justify-content-center align-items-center mx-0 w-100">
            <div class="col-lg-8">
                <div :class="{'card mb-5':true, 'py-5':!searchMinimize}">
                    <div class="card-body">
                        <h1 class="text-center text-primary mb-3" v-if="!searchMinimize || !searchForm">Encuentra una mesa de regalo</h1>
                        <form type="POST" class="form-row justify-content-center" v-on:submit.prevent="getBodas" ref="mesaForm">
                            <div :class="{'col-lg-8 mb-3':!searchMinimize, 'col-lg-12':searchMinimize}">
                                <div class="position-relative">
                                    <input 
                                        name="buscar"
                                        type="text" 
                                        class="form-control form-control-lg" 
                                        style="padding-right: 110px;" 
                                        placeholder="Buscar por nombre o apellido de cualquiera de los festejados" 
                                        v-model="form.search"
                                        required>
                                    <div class="position-absolute" style="right: 5px; top: 5px;">
                                        <button class="btn btn-primary" type="submit" :disabled="loading || !validateForm">
                                            <BtnLoading v-if="loadingMesa" />
                                            <span v-else>
                                                <span class="ei-search"></span> Buscar
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="text-center mb-3" v-if="!searchMinimize">
                            ¿Necesitas ayuda? <a href="#">Haz click aquí</a>
                        </div>
                        <h5 class="text-muted text-end mt-2" v-if="searchMinimize">
                            Se {{bodas.total>1 ? 'han' : 'ha'}} encontrado {{bodas.total}} {{bodas.total>1 ? 'celebraciones' : 'celebración'}}.
                        </h5>
                    </div>
                    <div class="card-body" v-if="searchMinimize">
                        <MesasList v-for="(boda, index) in bodasFiltered" :key="index" :data="boda" />
                    </div>

                    <div v-if="user.invitaciones && user.invitaciones.length>0" class="card-body">
                        <h2 class="text-muted">Tus invitaciones ({{user.invitaciones.length}})</h2>
                        <MesasList v-for="(invitacion, index) in user.invitaciones" :key="index" :data="invitacion.boda" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ImgBG from '@/assets/img/backgrounds/portada2.jpg'
import EmptyBoda from '@/assets/img/mesas/camera.png'
import BtnLoading from '@/components/BtnLoading.vue'
import MesasList from '@/components/mesas/MesasList.vue'

export default {
    name: 'mesa-regalo-index',
    components: {
        BtnLoading,
        MesasList
    },
    data(){
        return {
            ImgBG,
            EmptyBoda,
            loadingMesa: false,
            validateForm: false,
            form: {
                search: ''
            }
        }
    },
    mounted(){
        this.form.search = this.buscar
        if(this.buscar){
            this.getData()
        }else{
            this.$store.commit('bodas', [])
        }
        this.checkValidity()
    },
    methods: {
        getBodas(){
            this.checkValidity()
            if (!this.validateForm) {
                return
            }
            this.getData()
        },
        getData(){
            this.loadingMesa = true
            this.$store.dispatch('getBodas', this.form).then((res) => {
                return res
            }).catch(err => {
                this.$toast.error('Intenta nuevamente')
                return err
            }).then(() => {
                this.loadingMesa = false
            })
        },
        checkValidity(){
            if (!this.$refs.mesaForm){
                this.validateForm = false
            }else{
                this.validateForm = this.$refs.mesaForm.checkValidity()
            }
        },
    },
    computed: {
        parameters(){
            return this.$route.query || {}
        },
        buscar(){
            return this.parameters.buscar || ''
        },
        searchForm(){
            return this.form.search || ''
        },
        loading() {
            return this.$store.getters.loading
        },
        bodas() {
            return this.$store.getters.bodas || {}
        },
        bodasFiltered(){
            return this.bodas.data || []
        },
        user() {
            return this.$store.getters.currentUser || {}
        },
        searchMinimize(){
            return this.bodasFiltered.length>0
        }
    },
    watch: {
        searchForm(val) {
            if(val){
                this.$router.push({name: 'mesas.index', query: {buscar: val}})
            }else{
                this.$router.push({name: 'mesas.index'})
            }
        },
        'form':{
            handler(val){
                this.checkValidity()
                return val
            },
            deep: true
        }
    }
}
</script>